// App.js

import React, { useEffect, useState } from 'react';
import './App.css';

function App() {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    // Simulating a delay for the text to appear (you can adjust the delay as needed)
    const delay = setTimeout(() => {
      setIsVisible(true);
    }, 4000);

    return () => clearTimeout(delay);
  }, []);

  return (
    <div className="App">
      <div className="background-image">
        <div className="content">
          <h1 className={`text ${isVisible ? 'visible' : ''}`}>
            More information coming soon...
          </h1>
        </div>
      </div>
    </div>
  );
}

export default App;
